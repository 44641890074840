<template>
  <div class="row">
    <div class="access-code-section-outer d-flex justify-content-center"
      v-bind:style="{ 'background-image': 'url(' + accesscodebgimg + ')' }" v-if="!showdataloader">
      <div class="text-center mt-auto mb-auto" :style="{ width: '392px' }">
        <!-- <div class="login-header-image text-center mb-5 pb-5">
          <img src="/assets/images/meraneta-logo-black.png" class="img-fluid" alt="" width="146" />
        </div> -->
        <div class="panel-card-access d-flex flex-column">
          <div class="access-header px-3 pt-2 pb-1">ACCESS CODE</div>
          <div class="access-sub-header">Welcome to {{ this.partyshortname }}</div>
          <div class="d-flex flex-column px-3">
            <div class="form-floating mb-0 mt-3">
              <input type="text" v-model="accessvalue" maxlength="15" v-on:keyup.enter="checkuseraccesscode()" class="form-control" id="accesscodeInput"
                autocomplete="off" placeholder="Access Code">
              <label for="accesscodeInput">Enter Access Code</label>
            </div>
            <div class="custom-error" v-if="v$.accessvalue.$error">
              {{ v$.accessvalue.$errors[0].$message }}
            </div>
            <div class="custom-error" v-if="accessvalue != '' && accesscodemsg">
              {{ this.accesscodemsg }}
            </div>
            <div class="my-4">
              <button class="btn access-submit-btn" type="submit" @click="checkuseraccesscode()" :disabled="showloader">
                <span v-if="!showloader">Submit</span>
                <div class="
                  spinner-border
                  text-light
                  custom-spinner-loader-btn
                " role="status" v-if="showloader"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-modal-spinner-loader-access" v-if="showdataloader">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
  </div>
</template>
<script>
import { required, helpers, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      accessvalue: "",
      showloader: false,
      accesscodemsg: '',
      client_info: '',
      accesscodebgimg: '',
      accesscodeList: [],
      baseUrl: window.location.origin,
      showdataloader: false,
      partyshortname: "",
    };
  },
  watch: {
    accessvalue() {
      this.v$.$validate();
    },
  },
  validations() {
    return {
      accessvalue: {
        required: helpers.withMessage("Please enter Access Code", required),
        maxLength: helpers.withMessage(
          "Password should be max 15 length",
          maxLength(15)
        ),
      },
    };
  },

  mounted() {
    localStorage["masterbucketname"] = 'meraneta_admin';
    this.getAccessCodeBannerImage();
    if (localStorage.client_info) {
      this.$router.push("/login");
    }
    if (this.baseUrl == 'http://localhost:8080' || this.baseUrl == 'http://localhost:8081') {
      this.client_path = 'meraneta-dev';
    } else {
      this.client_path = localStorage.masterbucketname;
    }
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  methods: {
    getAccessCodeBannerImage(e) {
      this.showdataloader = true;
      this.ApiService.getAccessCodeBannerImage(e).then((data) => {
        if (data.status == 200) {
          this.accesscodeList = data.data;
          this.accesscodebgimg = "https://storage.googleapis.com/" + this.client_path + "/Client/AccesscodeBannerImg/" + this.accesscodeList.maa63;
          this.partyshortname = this.accesscodeList.maa65
          this.showdataloader = false;
        } else {
          this.partyshortname = "";
          this.accesscodebgimg = "";
          this.showdataloader = false;
        }
      });
    },
    checkuseraccesscode() {
      this.v$.$validate();
      let fields = {};
      fields["maa32"] = this.accessvalue;
      if (!this.v$.$error) {
        this.showloader = true;
        this.ApiService.checkuseraccesscode(fields).then((items) => {
          if (items.status === 200) {
            this.showloader = false;
            localStorage["client_info"] = JSON.stringify(
              items.data.client_info
            );
            window.location.href = "/login";
          } else {
            this.showloader = false;
            this.accesscodemsg = items.message;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.access-code-section-outer {
  height: 100vh;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  /* background: transparent linear-gradient(43deg, #E6E9F8 0%, #E9ECF9 38%, #FFFFFF 100%) 0% 0% no-repeat padding-box; */
}

.access-code-section-outer .panel-card-access {
  border: 1.5px solid #E4E4E4;
  border-radius: 6px;
  text-align: center;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.access-code-section-outer .access-header {
  margin-top: -3rem;
  color: #ffffff;
  border: 1.5px solid #E4E4E4;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 1;
  background: #f76100;
  font-family: 'AcuminPro-Bold';
}

.access-code-section-outer .access-sub-header {
  font-size: 1.5rem;
  margin: 1.5rem 0 1rem 0;
  font-family: 'AcuminPro-SemiBold';
  line-height: 1.2;
}

.access-code-section-outer .access-submit-btn {
  width: 100%;
  background: #5266E5 0% 0% no-repeat padding-box;
  border-color: #5266E5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #FFFFFF;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  padding: 9px 11px;
  font-family: 'AcuminPro-Regular';
  margin-top: 4px;
}

.access-code-section-outer .access-submit-btn:focus,
.access-code-section-outer .access-submit-btn:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.access-code-section-outer .form-floating .form-control {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #c0c3c9;
  border-radius: 4px;
  font-family: 'AcuminPro-Regular';
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #0B294B;
}

.access-code-section-outer .form-floating .form-control:focus {
  box-shadow: none !important;
  outline: 0;
  text-decoration: none;
  background-color: #fff;
  border-color: #80bdff !important;
}

.access-code-section-outer .form-floating .form-control:hover {
  border: 1px solid #bcbec1;
}

.access-code-section-outer .custom-error {
  color: #de2525;
  font-family: 'AcuminPro-Regular';
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.52px;
  line-height: 20px;
}
.custom-modal-spinner-loader-access{
  background-color: #fff;
  transition-duration: 0.2s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: #9cb5d9;
}</style>
